import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Layout } from '../components/Layout';
import config from '../../data/SiteConfig';

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={`404 | ${config.siteTitle}`} />
        <main className={`article py-32 text-center`}>
          <h1>Page not found ❌</h1>
          <p>Ups, the page you're looking for has been removed or relocated.</p>
          <p>
            If you came here because of a broken link on my site, please contact me via{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:me@felixgerschau.com?subject=Broken+link"
            >
              email
            </a>
            .
          </p>
        </main>
      </Layout>
    );
  }
}

export default AboutPage;
